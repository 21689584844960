<template>
  <div class="apikeys-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-2">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getApiKeys"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-10">
                <div class="dataTables_filter text-md-right">
                  <b-button variant="primary" type="button" size="sm" @click="newApiKey">
                    <i class="uil uil-plus"></i> New Api Key
                  </b-button>
                  <div class="d-inline-flex align-items-center">
                    <b-form-input v-model="datatable.queries.search" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </div>
                  <button class="ml-1 btn btn-primary btn-sm" @click="getApiKeys">
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <template v-if="!loadingApiKeys">
              <b-table :items="apikeys" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc" :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page" :no-local-sorting="true" @sort-changed="sortChanged">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:cell(name)="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:cell(type)="data">
                  {{ data.item.type === 'popup' ? 'Coupon' : 'Automation' }}
                </template>
                <template v-slot:cell(conversion_rate)="data">
                  <p :class="{
                    'text-danger': data.item.conversion_rate <= 30,
                    'text-primary':
                      data.item.conversion_rate > 30 &&
                      data.item.conversion_rate <= 90,
                    'text-success': data.item.conversion_rate > 90,
                  }">
                    {{ data.item.conversion_rate }}
                  </p>
                </template>
                <template v-slot:cell(total_revenue)="data">
                  <p> {{ data.item.total_revenue | currency }} </p>
                </template>
                <template v-slot:cell(actions)="data">
                  <b-dropdown v-if="data.item.type !== 'popup'" variant="black" right
                    toggle-class="arrow-none text-muted pr-0">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-v"></i>
                    </template>

                    <b-dropdown-item @click="edit(data.item)">
                      <i class="uil uil-edit mr-2"></i>Edit
                    </b-dropdown-item>

                    <b-dropdown-item :disabled="loadingRemove === data.item.id" variant="danger"
                      @click="destroy(data.item)">
                      <i class="uil uil-trash mr-2"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalApiKeys }} apikeys
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="datatable.queries.page" :total-rows="totalApiKeys"
                        :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="new-apikey" v-model="showApiKeyModal"
      :title="selectedApiKey ? 'Edit ApiKey ' + selectedApiKey.name : 'New ApiKey'" hide-footer>
      <div v-if="apikey && apikey.accessToken">
        <p class="alert alert-warning"><b>IMPORTANT NOTE:</b> This API Key is only shown <b>ONCE</b>. Make note of it and
          store it in a safe, secure location!</p>
        <div>
          <div class="form-group">
            <label class="form-control-label">Secret</label>
            <textarea ref="scriptText" v-model="apikey.accessToken" name="code" cols="30" rows="6" class="form-control"
              readonly @click="copyApiKey"></textarea>
          </div>
          <div class="text-right mt-1">
            <a href="javascript:void(0)" @click="copyApiKey">
              Copy the API Secret
            </a>
          </div>
        </div>
        <div class="d-flex my-3 coupon-input-checkbox">
          <b-form-checkbox v-model="apikey.confirmed" name="confirmed">
            Got it! I have saved my API Key to use in my application.
          </b-form-checkbox>
        </div>
        <b-form-group class="text-center">
          <b-button variant="primary" :disabled="!apikey.confirmed" type="button" @click="showApiKeyModal = false">
            <span>Done</span>
          </b-button>
        </b-form-group>
      </div>
      <ValidationObserver v-else v-slot="{ handleSubmit, invalid, touched }" ref="apikeyForm">
        <form v-if="apikey" class="apikey-form" @submit.prevent="handleSubmit(submitData)">
          <TextInput v-model="apikey.name" label="Name" rules="required" name="name" />
          <b-form-group class="text-right">
            <b-button variant="primary" :disabled="loadingSubmit || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingSubmit" small />
              <span v-else>{{ selectedApiKey ? 'Update' : 'Create' }}</span>
            </b-button>
          </b-form-group>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete ApiKey">
      <p v-if="selectedApiKey">Are you sure you want to delete apikey <b>"{{
        selectedApiKey.name
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import Vue from 'vue'
export default {
  components: {
  },

  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', label: 'Name' },
          { key: 'created_at', sortable: true },
          { key: 'actions', thStyle: { width: '50px' } },
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'id',
          sort_desc: true,
          sort_direction: 'asc',
        },
      },
      loadingSubmit: false,
      showApiKeyModal: false,
      showDeleteModal: false,
      selectedApiKey: null,
      loadingApiKeys: false,
      loadingRemove: null,
      apikey: null,
    }
  },

  computed: {
    totalApiKeys() {
      return this.$store.getters['apikey/total']
    },

    apikeys() {
      return this.$store.getters['apikey/all'] || []
    },
  },

  mounted() {
    this.getApiKeys()
  },

  methods: {
    copyApiKey() {
      this.$refs.scriptText.select()
      this.$refs.scriptText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'API Key has been copied',
        type: 'success',
        duration: 5000,
      })
    },
    newApiKey() {
      this.apikey = {
        name: '',
        accessToken: '',
      }
      this.selectedApiKey = null
      this.showApiKeyModal = true
    },

    submitData() {
      this.loadingSubmit = true
      if (!this.selectedApiKey) {
        this.$store
          .dispatch('apikey/create', this.apikey)
          .then((res) => {
            this.$refs.apikeyForm.reset()
            this.loadingSubmit = false
            this.getApiKeys();
            this.apikey.id = res.data.data.id
            this.apikey.accessToken = res.data.data.access_token
          })
          .catch((err) => {
            console.log(err);
            this.loadingSubmit = false
          })
      } else {
        this.$store
          .dispatch('apikey/update', this.apikey)
          .then(() => {
            this.showApiKeyModal = false
            this.apikey = null
            this.$refs.apikeyForm.reset()
            this.loadingSubmit = false
            this.getApiKeys();
          })
          .catch(() => {
            this.loadingSubmit = false
          })
      }
    },

    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getApiKeys()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getApiKeys()
    },

    getApiKeys() {
      this.loadingApiKeys = true

      this.$store
        .dispatch('apikey/getAll', this.datatable.queries)
        .then(() => {
          this.loadingApiKeys = false
        })
        .catch(() => {
          this.loadingApiKeys = false
        })
    },

    edit(apikey) {
      this.apikey = {
        ...apikey
      }
      this.selectedApiKey = apikey
      this.showApiKeyModal = true
    },

    destroy(apikey) {
      this.showDeleteModal = true
      this.selectedApiKey = apikey
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedApiKey.id

      this.$store
        .dispatch('apikey/destroy', this.selectedApiKey.id)
        .then(() => {
          this.loadingRemove = null
          this.getApiKeys();
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>
<style lang="scss">
.coupon-input-checkbox {
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>  